// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient{
   background: -webkit-linear-gradient(#65C891,#3CCDE9);
            -webkit-background-clip: text;
                    background-clip: text;
            -webkit-text-fill-color: transparent;
}

.text3{
   font-size: 40px;
}

.content3{
   display:flex;
   flex-direction: row;
   margin:112px 100px 0px 100px;
   height:545px;
}

.image3{
   height:auto;
   width:40%;
}

.right{
   height:auto;
   width:60%;
}

@media only screen and (max-width:900px) {
   .content3{
      flex-direction: column;
      margin:100px 30px 0px 30px;
      text-align: justify;
      height: 400px;
   } 
   .image3{
      height:auto;
      width:100%;
      display:none;
   }
   
   .right{
      height:auto;
      width:100%;
   }

   .gradient{
      font-size:22px !important;
   }

   .text3{
      font-size:22px !important;
   }
   
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Content3/content3.css"],"names":[],"mappings":"AAAA;GACG,oDAAoD;YAC3C,6BAAqB;oBAArB,qBAAqB;YACrB,oCAAoC;AAChD;;AAEA;GACG,eAAe;AAClB;;AAEA;GACG,YAAY;GACZ,mBAAmB;GACnB,4BAA4B;GAC5B,YAAY;AACf;;AAEA;GACG,WAAW;GACX,SAAS;AACZ;;AAEA;GACG,WAAW;GACX,SAAS;AACZ;;AAEA;GACG;MACG,sBAAsB;MACtB,0BAA0B;MAC1B,mBAAmB;MACnB,aAAa;GAChB;GACA;MACG,WAAW;MACX,UAAU;MACV,YAAY;GACf;;GAEA;MACG,WAAW;MACX,UAAU;GACb;;GAEA;MACG,yBAAyB;GAC5B;;GAEA;MACG,yBAAyB;GAC5B;;AAEH","sourcesContent":[".gradient{\n   background: -webkit-linear-gradient(#65C891,#3CCDE9);\n            background-clip: text;\n            -webkit-text-fill-color: transparent;\n}\n\n.text3{\n   font-size: 40px;\n}\n\n.content3{\n   display:flex;\n   flex-direction: row;\n   margin:112px 100px 0px 100px;\n   height:545px;\n}\n\n.image3{\n   height:auto;\n   width:40%;\n}\n\n.right{\n   height:auto;\n   width:60%;\n}\n\n@media only screen and (max-width:900px) {\n   .content3{\n      flex-direction: column;\n      margin:100px 30px 0px 30px;\n      text-align: justify;\n      height: 400px;\n   } \n   .image3{\n      height:auto;\n      width:100%;\n      display:none;\n   }\n   \n   .right{\n      height:auto;\n      width:100%;\n   }\n\n   .gradient{\n      font-size:22px !important;\n   }\n\n   .text3{\n      font-size:22px !important;\n   }\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
