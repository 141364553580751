// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer-Text{
    font-size: 18px;
}
.Image1{
    height: 40px;
    width: 40px;
}

.Image2{
    height: 30px;
    width: 30px;
}
@media only screen and (max-width:600px) {
    .Footer-Text{
        font-size: 12px;
    }

    .Image1{
        height: 30px;
        width: 30px;
    }
    
    .Image2{
        height: 25px;
        width: 25px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,YAAY;QACZ,WAAW;IACf;EACF","sourcesContent":[".Footer-Text{\n    font-size: 18px;\n}\n.Image1{\n    height: 40px;\n    width: 40px;\n}\n\n.Image2{\n    height: 30px;\n    width: 30px;\n}\n@media only screen and (max-width:600px) {\n    .Footer-Text{\n        font-size: 12px;\n    }\n\n    .Image1{\n        height: 30px;\n        width: 30px;\n    }\n    \n    .Image2{\n        height: 25px;\n        width: 25px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
