// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer-Details{
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px 0px 0px 0px ;
}

.Box{
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width:900px) {
    .Footer-Details{
        padding: 10px 40px;
        display: flex;
        flex-direction: column;
       justify-content: center;
       align-items: center;
        margin: 50px 0px 0px 0px ;
    }
    .Box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/FooterDetail/FooterDetail.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI;QACI,kBAAkB;QAClB,aAAa;QACb,sBAAsB;OACvB,uBAAuB;OACvB,mBAAmB;QAClB,yBAAyB;IAC7B;IACA;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;IACvB;EACF","sourcesContent":[".Footer-Details{\n    padding: 10px 40px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin: 50px 0px 0px 0px ;\n}\n\n.Box{\n    display: flex;\n    flex-direction: column;\n}\n\n@media only screen and (max-width:900px) {\n    .Footer-Details{\n        padding: 10px 40px;\n        display: flex;\n        flex-direction: column;\n       justify-content: center;\n       align-items: center;\n        margin: 50px 0px 0px 0px ;\n    }\n    .Box{\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
