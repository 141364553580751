// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Content6{
    width:100%;
    height:auto;
    display: flex;
    flex-direction: row;
    padding:40px 100px;
}

.content{
    display:flex;
    flex-direction:row !important;
}

.Content61{
    height:400px;
    width:33.33%;
    padding:30px 30px;
}

.Content612{
    height:300px;
    border-radius:30px;
}

.box61{
    background-color:black;
}
.box62{
    background-color: black;
}
.box63{
    background-color: black;
}
.content{
    display: flex;
    flex-direction: row;;
}
.content:hover{
    .box61{
        background-color:#4FC7EC;
    }
    .box62{
        background-color: #65C891;
    }
    .box63{
        background-color: #EB28A9;
    }
}


@media only screen and (max-width:900px) {
    .content{
        flex-direction: column !important;
        padding:0px;
    }

    .content-counter{
        display: none;
    }
    
    .Content61{

       width:100%;
    }

  }`, "",{"version":3,"sources":["webpack://./src/components/Home/Content6/Content6.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,yBAAyB;IAC7B;IACA;QACI,yBAAyB;IAC7B;AACJ;;;AAGA;IACI;QACI,iCAAiC;QACjC,WAAW;IACf;;IAEA;QACI,aAAa;IACjB;;IAEA;;OAEG,UAAU;IACb;;EAEF","sourcesContent":[".Content6{\n    width:100%;\n    height:auto;\n    display: flex;\n    flex-direction: row;\n    padding:40px 100px;\n}\n\n.content{\n    display:flex;\n    flex-direction:row !important;\n}\n\n.Content61{\n    height:400px;\n    width:33.33%;\n    padding:30px 30px;\n}\n\n.Content612{\n    height:300px;\n    border-radius:30px;\n}\n\n.box61{\n    background-color:black;\n}\n.box62{\n    background-color: black;\n}\n.box63{\n    background-color: black;\n}\n.content{\n    display: flex;\n    flex-direction: row;;\n}\n.content:hover{\n    .box61{\n        background-color:#4FC7EC;\n    }\n    .box62{\n        background-color: #65C891;\n    }\n    .box63{\n        background-color: #EB28A9;\n    }\n}\n\n\n@media only screen and (max-width:900px) {\n    .content{\n        flex-direction: column !important;\n        padding:0px;\n    }\n\n    .content-counter{\n        display: none;\n    }\n    \n    .Content61{\n\n       width:100%;\n    }\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
