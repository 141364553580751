// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body{
  background: linear-gradient(180deg, grey, black);
}

.projects{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
}
.curated{
  font-size: 30px;
  font-weight: bold;
}
.tiles{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center
}
.content5{
  margin:50px 100px 0px 100px;
}

.react-multiple-carousel__arrow--left {
  left:10px;
  }

  .react-multiple-carousel__arrow--right {
    right:10px
    }
@media only screen and (max-width:900px) {
  .tiles{
    flex-direction:column;
    
  }

  .projects{
    display:flex;
    flex-direction:column;
  }

  .curated{
    font-size: 30px;
    font-weight: bold;
  }
  .content5{
    margin:40px 0px;
  }
  
}


`, "",{"version":3,"sources":["webpack://./src/components/Home/Content5/content5.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;AAClD;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB;AACF;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,SAAS;EACT;;EAEA;IACE;IACA;AACJ;EACE;IACE,qBAAqB;;EAEvB;;EAEA;IACE,YAAY;IACZ,qBAAqB;EACvB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;;AAEF","sourcesContent":[".card-body{\n  background: linear-gradient(180deg, grey, black);\n}\n\n.projects{\n  display:flex;\n  flex-direction:row;\n  justify-content: center;\n  align-items: center;\n}\n.curated{\n  font-size: 30px;\n  font-weight: bold;\n}\n.tiles{\n  display:flex;\n  flex-direction:row;\n  justify-content:center;\n  align-items:center\n}\n.content5{\n  margin:50px 100px 0px 100px;\n}\n\n.react-multiple-carousel__arrow--left {\n  left:10px;\n  }\n\n  .react-multiple-carousel__arrow--right {\n    right:10px\n    }\n@media only screen and (max-width:900px) {\n  .tiles{\n    flex-direction:column;\n    \n  }\n\n  .projects{\n    display:flex;\n    flex-direction:column;\n  }\n\n  .curated{\n    font-size: 30px;\n    font-weight: bold;\n  }\n  .content5{\n    margin:40px 0px;\n  }\n  \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
