// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body{
  background: linear-gradient(180deg, grey, black);
}
  
  .projects{
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
  }
  .curated{
    font-size: 30px;
    font-weight: bold;
  }
  .tiles{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center
  }
  .content7{
    margin:0px 100px;
  }
  .react-multiple-carousel__arrow--left {
  /* left:50px;
  top:520px; */
  background: #4FC7EC;
  border-radius: 0;
  }

  .react-multiple-carousel__arrow--right {
    /* right:1150px;
    top:520px; */
    background: #65C891;
  border-radius: 0;
    }
  @media only screen and (max-width:900px) {
    .tiles{
      flex-direction:column;
      
    }
  
    .projects{
      display:flex;
      flex-direction:column;
    }
  
    .curated{
      font-size: 30px;
      font-weight: bold;
    }
    .content7{
      margin:40px 0px;
    }
    
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/components/Home/Content7/Content7.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;AAClD;;EAEE;IACE,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB;EACF;EACA;IACE,gBAAgB;EAClB;EACA;EACA;cACY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB;;EAEA;IACE;gBACY;IACZ,mBAAmB;EACrB,gBAAgB;IACd;EACF;IACE;MACE,qBAAqB;;IAEvB;;IAEA;MACE,YAAY;MACZ,qBAAqB;IACvB;;IAEA;MACE,eAAe;MACf,iBAAiB;IACnB;IACA;MACE,eAAe;IACjB;;EAEF","sourcesContent":[".card-body{\n  background: linear-gradient(180deg, grey, black);\n}\n  \n  .projects{\n    display:flex;\n    flex-direction:row;\n    justify-content: center;\n    align-items: center;\n  }\n  .curated{\n    font-size: 30px;\n    font-weight: bold;\n  }\n  .tiles{\n    display:flex;\n    flex-direction:row;\n    justify-content:center;\n    align-items:center\n  }\n  .content7{\n    margin:0px 100px;\n  }\n  .react-multiple-carousel__arrow--left {\n  /* left:50px;\n  top:520px; */\n  background: #4FC7EC;\n  border-radius: 0;\n  }\n\n  .react-multiple-carousel__arrow--right {\n    /* right:1150px;\n    top:520px; */\n    background: #65C891;\n  border-radius: 0;\n    }\n  @media only screen and (max-width:900px) {\n    .tiles{\n      flex-direction:column;\n      \n    }\n  \n    .projects{\n      display:flex;\n      flex-direction:column;\n    }\n  \n    .curated{\n      font-size: 30px;\n      font-weight: bold;\n    }\n    .content7{\n      margin:40px 0px;\n    }\n    \n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
