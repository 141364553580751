// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content2{
    margin:50px 100px 0px 100px;
}

.content22{
    display:flex;
    flex-direction:row;
    margin:20px 0px;
}

.Image{
    height:70px;
    width:70px;
}
@media only screen and (max-width:900px) {
    .content2{
        display:flex;
        flex-direction:column;
        height:auto;
        margin:50px 30px 0px 30px;
    }

    .Image{
        height:50px;
        width:50px;
    }
    .content22{
        flex-direction:column;
        justify-content: center;
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Content2/content2.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,UAAU;AACd;AACA;IACI;QACI,YAAY;QACZ,qBAAqB;QACrB,WAAW;QACX,yBAAyB;IAC7B;;IAEA;QACI,WAAW;QACX,UAAU;IACd;IACA;QACI,qBAAqB;QACrB,uBAAuB;QACvB,mBAAmB;IACvB;AACJ","sourcesContent":[".content2{\n    margin:50px 100px 0px 100px;\n}\n\n.content22{\n    display:flex;\n    flex-direction:row;\n    margin:20px 0px;\n}\n\n.Image{\n    height:70px;\n    width:70px;\n}\n@media only screen and (max-width:900px) {\n    .content2{\n        display:flex;\n        flex-direction:column;\n        height:auto;\n        margin:50px 30px 0px 30px;\n    }\n\n    .Image{\n        height:50px;\n        width:50px;\n    }\n    .content22{\n        flex-direction:column;\n        justify-content: center;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
