// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .animation {
    opacity: 0;
    transform: translateX(-300px);
    transition: all  1.5s ease-out;
    transition-delay: 0.4s;

}

.scroll-animation {
    opacity: 1;
    transform: translateX(0);
} */`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;GAWG","sourcesContent":["/* .animation {\n    opacity: 0;\n    transform: translateX(-300px);\n    transition: all  1.5s ease-out;\n    transition-delay: 0.4s;\n\n}\n\n.scroll-animation {\n    opacity: 1;\n    transform: translateX(0);\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
