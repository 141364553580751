// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Privacy{
    margin:50px 100px;
}

.Interpretetion{
    margin:30px 0px;
}

.collection{
    margin:10px 0px 70px 0px;
}

.Privacy p{
    font-size: 18px;
}

.Privacy h2,h3,h4{
    font-family: BDSuper;
}


@media only screen and (max-width:900px) {
    .Privacy{
        margin:50px 20px;
    }
    
  }
`, "",{"version":3,"sources":["webpack://./src/components/Privacy/Privacy.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,oBAAoB;AACxB;;;AAGA;IACI;QACI,gBAAgB;IACpB;;EAEF","sourcesContent":[".Privacy{\n    margin:50px 100px;\n}\n\n.Interpretetion{\n    margin:30px 0px;\n}\n\n.collection{\n    margin:10px 0px 70px 0px;\n}\n\n.Privacy p{\n    font-size: 18px;\n}\n\n.Privacy h2,h3,h4{\n    font-family: BDSuper;\n}\n\n\n@media only screen and (max-width:900px) {\n    .Privacy{\n        margin:50px 20px;\n    }\n    \n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
