// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Links{
    display: flex;
    align-items: center;
}
.logo{
    height: 52px;
    width: 34px;
}
.Navbar{
    padding:30px 0px;
}
.nav-links1{
    text-decoration: none;
    color:black;
    margin:0px 48px;
    font-size: 16px;
    font-family:Montserrat;
    font-weight: 800;
}
.contact{
    height:41px;
    width:152px;
}
.container1{
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    align-content: center;
}

.dropDown{
    display: none;
}

@media only screen and (max-width:900px) {
    

    .contact{
        height:40px;
        width:80px;
        font-size: 13px;
    }
    .logo{
        height: 40px;
        width: 40px;
    }

    .container1{

        justify-content: space-between;
        padding:0px 20px;
    }


   
}

@media only screen and (max-width:800px) {
    .dropDown{
        display: inline;
    }
    .Links{
        display: none;
    }
    .Navbar{
        padding:5px 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,WAAW;IACX,eAAe;IACf,eAAe;IACf,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,6BAA6B;IAC7B,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB;;AAEA;;;IAGI;QACI,WAAW;QACX,UAAU;QACV,eAAe;IACnB;IACA;QACI,YAAY;QACZ,WAAW;IACf;;IAEA;;QAEI,8BAA8B;QAC9B,gBAAgB;IACpB;;;;AAIJ;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,aAAa;IACjB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".Links{\n    display: flex;\n    align-items: center;\n}\n.logo{\n    height: 52px;\n    width: 34px;\n}\n.Navbar{\n    padding:30px 0px;\n}\n.nav-links1{\n    text-decoration: none;\n    color:black;\n    margin:0px 48px;\n    font-size: 16px;\n    font-family:Montserrat;\n    font-weight: 800;\n}\n.contact{\n    height:41px;\n    width:152px;\n}\n.container1{\n    display:flex;\n    flex-direction:row;\n    justify-content: space-around;\n    align-content: center;\n}\n\n.dropDown{\n    display: none;\n}\n\n@media only screen and (max-width:900px) {\n    \n\n    .contact{\n        height:40px;\n        width:80px;\n        font-size: 13px;\n    }\n    .logo{\n        height: 40px;\n        width: 40px;\n    }\n\n    .container1{\n\n        justify-content: space-between;\n        padding:0px 20px;\n    }\n\n\n   \n}\n\n@media only screen and (max-width:800px) {\n    .dropDown{\n        display: inline;\n    }\n    .Links{\n        display: none;\n    }\n    .Navbar{\n        padding:5px 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
